import { template as template_ee6f5f094b3343618e280a53828f75d4 } from "@ember/template-compiler";
const FKText = template_ee6f5f094b3343618e280a53828f75d4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
